<template>
  <div id="railyard">
    <gc-stage-account-header
      v-if="showBackButton && currentCompany?.name"
      class="global-components"
      button-label="Back to Overview"
      :name="currentCompany.name"
      :jurisdiction="currentCompany?.domestic_registration?.jurisdiction?.state_province_region || 'Unknown Jurisdiction'"
      @selected="goToOverview()"
    />

    <div class="search-container">
      <h3>How Can We Help?</h3>

      <div class="input">
        <b-input-group>
          <b-input-group-prepend>
            <b-input-group-text class="search-icon-container">
              <b-icon icon="search" class="search-icon" />
            </b-input-group-text>
          </b-input-group-prepend>
          <b-form-input
            v-model="query"
            class="search-stages"
            placeholder="Search By Stage Name"
            @input="debounceSearch"
          />
        </b-input-group>
      </div>
    </div>

    <div class="task-list">
      <gc-stage-card-button
        v-for="accountsStage in stages"
        :key="accountsStage.id"
        class="global-components"
        :title="accountsStage.stage.title"
        :description="accountsStage.stage.description"
        :statuses="JSON.stringify(getStatuses(accountsStage))"
        @selected="goToStage(accountsStage.id)"
      />
    </div>
    <div class="load-more">
      <ct-centered-spinner v-if="loading">
        Loading Stages...
      </ct-centered-spinner>
      <b-button
        v-else-if="showLoadMore"
        class="load-more-button"
        @click="loadStages()"
      >
        Load More
        <span class="ellipse">...</span>
      </b-button>
    </div>
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'StageViewer',
  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner.vue'),
  },
  data() {
    return {
      query: '',
      showBackButton: false,
      loading: true,
      offset: 1,
      typing: false,
      debounce: null,
      lastSearchedTerm: null,
      showLoadMore: true,
      stageLimit: 9,
      stages: [],
    }
  },
  computed: {
    ...mapGetters('companies', [
      'currentCompany',
    ]),
    ...mapGetters('stagelineV3', [
      'accountsStages',
    ]),
  },
  watch: {
    lastSearchedTerm() {
      this.offset = 1
      this.showLoadMore = true
      this.search()
    },
  },
  async mounted() {
    this.companyId = this.$route.params.companyId
    if (this.companyId && this.companyId !== this.currentCompany?.id) {
      await this.setCurrentCompany({ id: this.companyId })
    }

    this.showBackButton = true

    await this.loadStages(true)
  },
  methods: {
    ...mapActions('companies', [
      'setCurrentCompany',
    ]),
    ...mapActions('stagelineV3', [
      'getAndSetAccountsStages',
    ]),
    async loadStages(clear = false) {
      this.loading = true

      // Having the stages variable might seem redundant, but this is necessary to clear out the state for the Stage
      // Cards custom component.
      this.stages = []
      try {
        const previousLength = this.accountsStages.length
        await this.getAndSetAccountsStages({
          page: { number: this.offset, size: this.stageLimit },
          clear,
          include: 'stage',
          sort: ['stages_category_priority', 'stage_priority'],
          filter: {
            stage_name_i_cont: this.lastSearchedTerm,
            trackable_id_eq: this.currentCompany.id,
          },
        })
        this.stages = this.accountsStages
        if (this.accountsStages.length === previousLength || this.accountsStages.length % this.stageLimit !== 0)
          this.showLoadMore = false
        this.offset += 1
      } catch {
        // do nothing for now
      } finally {
        this.loading = false
      }
    },
    async goToStage(accountsStageId) {
      await this.$router.push({ name: 'stage-viewer', params: {
          companyId: this.currentCompany.id,
          accountsStageId,
        },
      }).catch(() => {})
    },
    async goToOverview() {
      await this.$router.push({ name: 'overview', params: {
          companyId: this.currentCompany.id,
        },
      }).catch(() => {})
    },
    debounceSearch() {
      this.resultsLoaded = false
      this.typing = true

      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.typing = false
        this.lastSearchedTerm = this.query
      }, 500)
    },
    async search() {
      await this.loadStages(true)
    },
    getStatuses(accountsStage) {
      return accountsStage.current_slide ? ['inProgress'] : ['new']
    },
  },
}
</script>

<style scoped lang="scss">
#railyard {
  margin: 5em 15%;
  overflow: hidden;
  width: 100%;

  .search-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
    justify-content: space-between;
    padding: 0;
    margin: auto 0;
    width: 100%;
  }

  .input {
    margin-top: 1em;
    width: 20em;

    .search-stages {
      width: auto;
      margin-bottom: 0;
      border-left: none;

      &:focus {
        outline: none;
        box-shadow: 0 0 0 rgb(255, 255, 255);
        border-color: #ced4da;
      }
    }

    .search-icon-container {
      background-color: $white;
      border-right: none;
      padding-right: 0;
    }
  }

  .task-list {
    display: grid;
    margin-top: 2em;
    grid-template-columns: repeat(3, minmax(15em, 1fr));
    gap: 1em;
  }

  .load-more {
    height: 6em;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1em;

    .ellipse {
      position: relative;
      top: -0.0625em;
      font-weight: 800;
      font-size: 2em;
    }

    .load-more-button {
      height: 3em;
      color: $ct-ui-primary;
      background-color: transparent;
      border: none;

      &:hover {
        text-decoration: underline;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  @media only screen and (max-width: 1157px) {
    .task-list {
      grid-template-columns: repeat(2, minmax(15em, 1fr));
    }
  }

  @media only screen and (max-width: 768px) {
    .task-list {
      grid-template-columns: repeat(1, minmax(15em, 1fr));
    }

    .container {
      justify-content: center;
    }
  }
}

// Override some of the Styles from global components
:deep(.p-tag-info) {
  background-color: #bbe0ff;
  color: #017bdf;
}
:deep(.p-tag-success) {
  background-color: #caf1d8;
  color: #188a42;
}
:deep(.p-tag-warning) {
  background-color: #feddc7;
  color: #ae510f;
}
:deep(.task-button) {
  width: 100%;
  height: 100%;
}
</style>
