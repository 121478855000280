var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "railyard" } },
    [
      _vm.showBackButton && _vm.currentCompany?.name
        ? _c("gc-stage-account-header", {
            staticClass: "global-components",
            attrs: {
              "button-label": "Back to Overview",
              name: _vm.currentCompany.name,
              jurisdiction:
                _vm.currentCompany?.domestic_registration?.jurisdiction
                  ?.state_province_region || "Unknown Jurisdiction",
            },
            on: {
              selected: function ($event) {
                return _vm.goToOverview()
              },
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "search-container" }, [
        _c("h3", [_vm._v("How Can We Help?")]),
        _c(
          "div",
          { staticClass: "input" },
          [
            _c(
              "b-input-group",
              [
                _c(
                  "b-input-group-prepend",
                  [
                    _c(
                      "b-input-group-text",
                      { staticClass: "search-icon-container" },
                      [
                        _c("b-icon", {
                          staticClass: "search-icon",
                          attrs: { icon: "search" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("b-form-input", {
                  staticClass: "search-stages",
                  attrs: { placeholder: "Search By Stage Name" },
                  on: { input: _vm.debounceSearch },
                  model: {
                    value: _vm.query,
                    callback: function ($$v) {
                      _vm.query = $$v
                    },
                    expression: "query",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "task-list" },
        _vm._l(_vm.stages, function (accountsStage) {
          return _c("gc-stage-card-button", {
            key: accountsStage.id,
            staticClass: "global-components",
            attrs: {
              title: accountsStage.stage.title,
              description: accountsStage.stage.description,
              statuses: JSON.stringify(_vm.getStatuses(accountsStage)),
            },
            on: {
              selected: function ($event) {
                return _vm.goToStage(accountsStage.id)
              },
            },
          })
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "load-more" },
        [
          _vm.loading
            ? _c("ct-centered-spinner", [
                _vm._v("\n      Loading Stages...\n    "),
              ])
            : _vm.showLoadMore
            ? _c(
                "b-button",
                {
                  staticClass: "load-more-button",
                  on: {
                    click: function ($event) {
                      return _vm.loadStages()
                    },
                  },
                },
                [
                  _vm._v("\n      Load More\n      "),
                  _c("span", { staticClass: "ellipse" }, [_vm._v("...")]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }